import React from 'react';
import './PolicyMobile.css';

const PolicyMobile: React.FC = () => {
  return (
    <div className="policy-container-mobile">
      <h1>ACCESSIBILITY POLICY</h1>
      <div className="policy-content-mobile">
        <div className="policy-column-mobile">
          <p>
            At WHEAR, we are committed to providing an accessible and user-friendly experience for all customers. We strive to meet the highest standards of accessibility and ensure that our website content is accessible to all. If you have difficulty accessing our site, please do not hesitate to contact us at the phone number or email address provided.
          </p>
        </div>
        <div className="policy-column-mobile">
          <p>
            We are dedicated to providing a unique and innovative experience for our customers. We are constantly striving to improve our services and welcome any feedback or suggestions you may have. Please feel free to reach out to us at any time.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PolicyMobile;
