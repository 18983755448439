import React, { useState, useEffect } from 'react';
import PolicyPC from './PolicyPC';
import PolicyMobile from './PolicyMobile';

const Policy: React.FC = () => {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

    const handleWindowSizeChange = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    console.log('Is Mobile:', isMobile);

    return (
        <div>
            {isMobile ? (
                <PolicyMobile />
            ) : (
                <PolicyPC />
            )}
        </div>
    );
};

export default Policy;
